import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Settings from '@material-ui/icons/Settings';
import Menu from '@material-ui/icons/Menu';
import RunAction from '../RunAction/RunAction';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    padding: '10px'
  },
  spacer: {
    flex: 1
  }
}));

export default function NavBar({onRunAll, onLogin, onOpenConfig, onOpenSettings, children}) {
  const classes = useStyles();
  const Header = ()=> <Typography variant="h6" className={classes.title}>
    WorkLink: Network Diagnostics
  </Typography>


  return (
    <AppBar position="sticky" color="primary">
      <Hidden smUp> <Header/> </Hidden>
      <Toolbar>
        <IconButton color="inherit" onClick={onOpenSettings}>
          <Menu />
        </IconButton>
        <Hidden xsDown> <Header/> </Hidden>
        <div className={classes.spacer}/>
        <IconButton onClick={onOpenConfig} color="inherit">
          <Settings />
        </IconButton>
        <IconButton onClick={onLogin} color="inherit">
          <AccountCircle />
        </IconButton>
        <RunAction onStart={onRunAll} color="secondary">Run All</RunAction>
      </Toolbar>
      {children}
    </AppBar>
  );
}
