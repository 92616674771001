import React, { useMemo } from 'react';
import {DataChannelThroughputTest, VideoBandwidthTest, WiFiPeriodicScanTest} from '../_testrtc/bandwidth_test';
import Call from '../_testrtc/call';

import Diagnostic, { useDiagnosticState } from '../Diagnostic/Diagnostic'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  video: {
    display: 'none'
  }
}));

// type: data
// Creates a loopback via relay candidates and tries to send as many packets
// with 1024 chars as possible while keeping dataChannel bufferedAmmount above
// zero.

// type: Video
// Measures video bandwidth estimation performance by doing a loopback call via
// relay candidates for 40 seconds. Computes rtt and bandwidth estimation
// average and maximum as well as time to ramp up (defined as reaching 75% of
// the max bitrate. It reports infinite time to ramp up if never reaches it.

// type: wifi / wifi2
// ?

export default function ThroughputDiagnostic({config, type, ipv6, runAll, description}) {
  const classes = useStyles();
  const [state, actions] = useDiagnosticState();

  const test = useMemo(() => {
    return {
      data: () => new DataChannelThroughputTest({settings:config, ...actions}),
      video: () => new VideoBandwidthTest({settings:config, ...actions, doGetUserMedia}),
      wifi: () => new WiFiPeriodicScanTest({settings:config, ...actions, testDurationMs: 0.2*60*1000}, Call.isRelay),
      wifi2: () => new WiFiPeriodicScanTest({settings:config, ...actions, testDurationMs: 0.2*60*1000}, Call.isNotHostCandidate)
    }[type]();
  }, [config, type, actions]);

  const runTest = () => {
    actions.run();
    test.run();
  }

  return <div>
    <Diagnostic title="Throughput" state={state} onStart={runTest} runAll={runAll}>
      <p><b>{description}</b> {config.turnURI}</p>
      {type ==='video' && <video autoPlay={true} id="videoElement" controls className={classes.video}></video>}
    </Diagnostic>
  </div>
}

// Ask user permission to use camera.
function doGetUserMedia(constraints, onSuccess, onFail){
  try {
    navigator.mediaDevices.getUserMedia(constraints)
      .then(function(stream) {
        var video = document.querySelector("video");
        video.srcObject = stream;
        video.onloadedmetadata = function(e) {
          video.play();
        };
        onSuccess.apply(this, arguments);
      })
      .catch(function(error) {
        if (onFail) {
          onFail.apply(this, arguments);
        } else {
          console.log('Failed to get access to local media due to ' +
              'error: ' + error);
        }
      });
  } catch (e) {
    console.log(e);
  }
}
