import Button from '@material-ui/core/Button';
import PlayArrow from '@material-ui/icons/PlayArrow';

export default function RunAction({onStart, title, children, color}) {
  return <Button
        variant="contained"
        color={color||"primary"}
        size="small"
        startIcon={<PlayArrow />}
        onClick={onStart}
      >
    {title}
    {children}
  </Button>
}
