import Drawer from '@material-ui/core/Drawer';
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

export default function Settings({settings, setSettings, open, setOpen}) {
  const toggleKey = (key) => {
    setSettings({
      ...settings,
      turn: {
        ...settings.turn,
        [key]: {
          ...settings.turn[key],
          enabled:!settings.turn[key].enabled
        }
      }
    })
  }

  return <Drawer open={open} onClose={()=>setOpen(false)}>
    <Card style={{height:'100%'}}>
      <CardHeader title="Turn settings"/>
      <CardContent>
        <FormGroup>
          {Object.keys(settings.turn).map(key=> (
            <FormControlLabel
              key={key}
              label={settings.turn[key].description}
              control={
                <Checkbox
                  checked={settings.turn[key].enabled}
                  onChange={()=>toggleKey(key)}
                  name={key}
                  color="primary"
                />
              }
            />
          ))}
        </FormGroup>
      </CardContent>
    </Card>
  </Drawer>
}
