import React, { useReducer, useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Alert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import RunAction from '../../RunAction/RunAction';

const useStyles = makeStyles((theme) => ({
  progress: {
    width: "200px"
  },
  alert: {
    margin: "10px"
  }
}));

const initialState = { severity: "info", message: null, info: [], running: false, progress: 0 };

function reducer(state, action) {
  switch (action.type) {
    case 'run':
      return {...initialState, running: true};
    case 'done':
      return {...state, running: false};
    case 'info':
      return {...state, info: [...state.info, action.message] };
    case 'report':
      return {...state, severity: action.severity, message: action.message };
    case 'progress':
      return {...state, progress: action.progress };
    default:
      throw new Error();
  }
}

export function useDiagnosticState() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = {
    reportWarning: (message) => dispatch({type:'report', severity: 'warning', message}),
    reportError: (message) => dispatch({type:'report', severity: 'error', message}),
    reportFatal: (message) => dispatch({type:'report', severity: 'error', message}),
    reportSuccess: (message) => dispatch({type:'report', severity: 'success', message}),
    reportInfo: (message) => dispatch({type:'info', message}),
    setProgress: (progress) => dispatch({type:'progress', progress}),
    done: () => dispatch({type:'done'}),
    run: ()=> dispatch({type:'run'})
  }

  return [state, actions]
}

export default function Diagnostic({state, title, children, onStart, runAll}) {
  const classes = useStyles();
  const skippedFirst = useRef(false);

  /*eslint-disable react-hooks/exhaustive-deps */
  /*todo wrap onStart into callback*/
  useEffect (() => {
    !skippedFirst.current
      ? skippedFirst.current = true
      : onStart()
  },[runAll]);
  /*eslint-enable react-hooks/exhaustive-deps */

  return (
    <Alert severity={state.severity} className={classes.alert} action={<RunAction onStart={onStart} title="Run"/>}>
      <AlertTitle>{title}</AlertTitle>
      {children}
      {state.progress > 0 && <LinearProgress variant="determinate" value={state.progress} className={classes.progress} />}
      {state.message && <p> {state.message} </p>}
      {state.info.length > 0 && <p> {state.info.join('; ')} </p>}
      {(!state.progress && !state.message) && <p> Not run yet </p>}
    </Alert>
  )
}
