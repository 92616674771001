import React, { useMemo } from 'react';
import NetworkTest from '../_testrtc/nettest';
import Call from '../_testrtc/call';

import Diagnostic, { useDiagnosticState } from '../Diagnostic/Diagnostic'

// Type "udp":
// Test whether it can connect via UDP to a TURN server
// Get a TURN config, and try to get a relay candidate using UDP.

// Type "tcp":
// Test whether it can connect via UDP to a TURN server
// Get a TURN config, and try to get a relay candidate using UDP.

// ipv6 "true":
// Test whether it is IPv6 enabled.
// Turn on IPv6, and try to get an IPv6 host candidate.
// Not reliable due to security: https://bloggeek.me/psa-mdns-and-local-ice-candidates-are-coming/

export default function NetworkDiagnostic({config, type, ipv6, runAll, description}) {
  const [state, actions] = useDiagnosticState();

  const networkTest = useMemo(() => {
    return new NetworkTest({settings: config, ...actions}, type, {optional: [{googIPv6: ipv6||false}]}, ipv6 ? Call.isIpv6 : Call.isRelay);
  }, [config, actions, type, ipv6]);

  const runNetworkTest = () => {
    actions.run();
    networkTest.run();
  }

  return  <Diagnostic title="Network" state={state} onStart={runNetworkTest} runAll={runAll}>
    <p><b>{description}</b> {config.turnURI}</p>
  </Diagnostic>
}
