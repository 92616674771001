import React from 'react';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    flex: 1,
  },
  editor: {
    height: '100%',
    backgroundColor: 'white',
    '& > div': {
      height: '100%'
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Config({config, setConfig, open, setOpen}) {
  const classes = useStyles();

  return (
    <Dialog fullScreen open={open} onClose={()=>setOpen(false)} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            RAR Config Editor
          </Typography>
          <Button autoFocus color="inherit" onClick={()=>setOpen(false)}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.editor}>
        <Editor
          className={classes.editor}
          history={true}
          mode="code"
          allowedModes={["tree", "code"]}
          value={config}
          onChange={setConfig}
        />
      </div>
    </Dialog>
    );
}
