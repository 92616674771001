import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import NetworkDiagnostic from './NetworkDiagnostic/NetworkDiagnostic'
import ConnectivityDiagnostic from './ConnectivityDiagnostic/ConnectivityDiagnostic'
import ThroughputDiagnostic from './ThroughputDiagnostic/ThroughputDiagnostic'


const useStyles = makeStyles((theme) => ({
  title: {
    margin: '13px'
  },
}));


export default function TurnDiagnostics({runAll, servers, settings}) {
  const classes = useStyles();

  var serversCongig = servers.map(server => ({
    turnURI: `turn:${server.address}:${server.port}`,
    stunURI: `stun:${server.address}:${server.port}`,
    turnUsername: server.username,
    turnCredential: server.password,
    name: `${server.address}:${server.port}`
  }));
  console.log(settings);
  // filtering out same servers, but, potentially, with different protocol.
  // we test both UDP and TCP, for each server, regardless of server.protocol.
  serversCongig = serversCongig.filter((server, index) => index === serversCongig.findIndex(another => server.turnURI === another.turnURI))

  return <>
    {serversCongig.map(config => <div key={JSON.stringify(config)}>
      <Typography color="primary" align="center" variant="h6" className={classes.title}>{config.name}</Typography>
      {settings.nd_udp.enabled && <NetworkDiagnostic runAll={runAll} config={config} type="udp" description={settings.nd_udp.description}/>}
      {settings.nd_tcp.enabled && <NetworkDiagnostic runAll={runAll} config={config} type="tcp"  description={settings.nd_tcp.description}/>}
      {settings.nd_ipv6.enabled && <NetworkDiagnostic runAll={runAll} config={config} ipv6 description={settings.nd_ipv6.description}/>}
      {settings.cnt_relay.enabled && <ConnectivityDiagnostic runAll={runAll} config={config} mode="relay" description={settings.cnt_relay.description}/>}
      {settings.cnt_reflex.enabled && <ConnectivityDiagnostic runAll={runAll} config={config} mode="reflexive" description={settings.cnt_reflex.description}/>}
      {settings.cnt_host.enabled && <ConnectivityDiagnostic runAll={runAll} config={config} mode="host" description={settings.cnt_host.description}/>}
      {settings.thr_data.enabled && <ThroughputDiagnostic runAll={runAll} config={config} type="data" description={settings.thr_data.description}/> }
      {settings.thr_wifi.enabled && <ThroughputDiagnostic runAll={runAll} config={config} type="wifi" description={settings.thr_wifi.description}/>}
      {settings.thr_wifi2.enabled && <ThroughputDiagnostic runAll={runAll} config={config} type="wifi2" description={settings.thr_wifi2.description}/>}
      {settings.thr_video.enabled && <ThroughputDiagnostic runAll={runAll} config={config} type="video" description={settings.thr_video.description}/>}
    </div>)}
  </>
}
