import { useState } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


import NavBar from './components/NavBar/NavBar'
import Login from './components/Login/Login'
import Config from './components/Config/Config'
import Settings from './components/Settings/Settings'
import TurnDiagnostics from './components/TurnDiagnostics/TurnDiagnostics'

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {main: "#eea905"},
    secondary: { main: "#222" },
  },
});

const sampleConfig = {
  "regions": [{
    "region": "US",
    "turn_servers": [{
      "address": "proxy-aws-us.scopear.com",
      "port": 3478,
      "username": "",
      "password": ""
    }]
  }]
}

const defaultSettings = {
  turn: {
    nd_udp: { enabled: true, description: "UDP network test"},
    nd_tcp: { enabled: true, description: "TCP network test"},
    nd_ipv6: { enabled: true, description: "IPv6 network test (not reliable)"},
    cnt_relay: { enabled: true, description: "Relay connection test"},
    cnt_reflex: { enabled: false, description: "Reflex connection test"},
    cnt_host: { enabled: false, description: "Host connection test"},
    thr_data: { enabled: true, description: "Data throughput test"},
    thr_wifi: { enabled: true, description: "WiFi Relay throughput test"},
    thr_wifi2: { enabled: true, description: "WiFi2 NotHost throughput test"},
    thr_video: { enabled: true, description: "Video throughput test"},
  }
}

function App() {
  const [runAll, setRunAll] = useState(0);
  const [loginOpen, setLoginOpen] = useState(true);
  const [configOpen, setConfigOpen] = useState(false);
  const [config, setConfig] = useState(sampleConfig);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [settings, setSettings] = useState(defaultSettings);
  const [region, setRegion] = useState(0);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="App">
        <NavBar onRunAll={()=>setRunAll(runAll+1)} onLogin={()=>setLoginOpen(true)} onOpenSettings={()=>setSettingsOpen(true)} onOpenConfig={()=>setConfigOpen(true)}>
          { config?.regions?.length > 0 && (
            <Tabs value={region} onChange={(e,v) => setRegion(v)} variant="fullWidth">
              {config.regions.map(({region}) => (
                <Tab label={region} key={region}/>
              ))}
            </Tabs>
          )}
        </NavBar>
        <Login open={loginOpen} setOpen={setLoginOpen} setConfig={setConfig}/>
        <Settings open={settingsOpen} setOpen={setSettingsOpen} settings={settings} setSettings={setSettings}/>
        <Config config={config} setConfig={setConfig} open={configOpen} setOpen={setConfigOpen}/>
        <Container disableGutters={true}>
          { config?.regions?.length > 0
            ? <TurnDiagnostics servers={config.regions[region].turn_servers} runAll={runAll} settings={settings.turn}/>
            : <center><h4>Configuration is empty, please login or use editor</h4></center>
          }
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default App;
