import React, { useMemo } from 'react';

import RunConnectivityTest from '../_testrtc/conntest';
import Call from '../_testrtc/call';

import Diagnostic, { useDiagnosticState } from '../Diagnostic/Diagnostic'


// Mode relay:
// Set up a datachannel between two peers through a relay
// and verify data can be transmitted and received
// (packets travel through the public internet)

// Mode reflexive:
// Set up a datachannel between two peers through a public IP address
// and verify data can be transmitted and received
// (packets should stay on the link if behind a router doing NAT)

// Mode host:
// Set up a datachannel between two peers through a local IP address
// and verify data can be transmitted and received
// (packets should not leave the machine running the test)

export default function ConnectivityDiagnostic({config, mode, runAll, description}) {
  const [state, actions] = useDiagnosticState();

  const connectivityTest = useMemo(() => {
    return new RunConnectivityTest({...actions, settings:config},
      {
        relay: Call.isRelay,
        reflexive: Call.isReflexive,
        host: Call.isHost
      }[mode]
    );
  }, [config, mode, actions]);

  const runConnectivityTest = () => {
    actions.run();
    connectivityTest.run();
  }

  return  <Diagnostic title="Connectivity" state={state} onStart={runConnectivityTest} runAll={runAll}>
    <p><b>{description}</b> {config.turnURI}</p>
  </Diagnostic>
}
