import { useEffect, useState } from 'react';
import qs from 'qs';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

export default function Login({open, setOpen, setConfig}) {
  const parsed = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const token = parsed.user_token;
  const CMS_URL = `${window.env.REACT_APP_CMS_URL || process.env.REACT_APP_CMS_URL}`;

  /* eslint-disable react-hooks/exhaustive-deps */
  /* will be run on initial page load only*/
  useEffect(()=>{
    if(!token) return;
    setLoading(true);
    setError(null);
    fetch(`${CMS_URL}/api/v2/users/sign_in`, {
      headers: { "Content-Type": "application/json", "Accept": "application/json" },
      body: JSON.stringify({user:{auth_token: token }}),
      method: "POST"
    }).then(function(response) {
      if(response.ok) { return response.json() }
      throw new Error(response.statusText);
    }).then(function(data) {
      setLoading(false);
      setOpen(false);
      setConfig(data.rar_configuration);
    }).catch((e) => {
      setLoading(false);
      setError(e.toString());
    });
  },[])
  /*eslint-enable react-hooks/exhaustive-deps */

  const login = () => {
    const callback = window.location.toString().replace(window.location.search, "");
    window.location.replace(`${CMS_URL}/mobile/sessions/new?product=worklink&callback=${encodeURIComponent(callback)}`);
  }

  return <Dialog onClose={()=>setOpen(false)} aria-labelledby="simple-dialog-title" open={open} PaperProps={{elevation: 3}}>
    <DialogTitle id="simple-dialog-title">Login via CMS</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        Fetch TURN configuration from CMS to test same configuration as provided to Worklink app.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={()=>setOpen(false)} color="primary">
        Cancel
      </Button>
      <Button onClick={login} color="primary" variant="contained" startIcon={loading && <CircularProgress color="secondary" size={10}/>}>
        Login
      </Button>
    </DialogActions>
    {error && <Alert severity="error" variant="filled">{error}</Alert>}
  </Dialog>

}
